<template>
    <div class="relative">
        <div class="w-full absolute block z-30">
            <Header/>
        </div>
    <div class="blogs-banner-container space-top w-full">
        <div class="relative h-full w-full flex justify-center flex-col">
            <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-8" src="../../../assets/images/home-page/blogs/blogs-banner.png" alt="Blogs Banner">
            <div class="flex flex-col -mb-6 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20">
                <h1 class="blogs-banner-title flex mobileL:static mobileL:justify-center ml-6 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl">
                    Blogs
                </h1>
                <p class="mobileL:mt-4 text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl ml-2 px-4 mr-6 mobileL:mr-0 text-white-1">
                    Find beauty inspiration, the latest Lare Bare news, recommendations and more!
                </p>
            </div>
        </div>
    </div>
    <div class="relative flex items-center z-20">
        <img class="relative w-full h-full object-cover" src="../../../assets/images/home-page/blogs/banner-3.png" alt="Background Image">
        <h2 class="absolute ml-6 md:ml-36 coming-soon-text text-3xl md:text-4xl lg:text-8xl 2xl:text-9xl">
            Coming Soon!
        </h2>
    </div>
    <div class="relative flex bg-brown-4 items-center justify-start">
        <img class="absolute -right-20 lg:-right-8 2xl:-right-10 bottom-0 w-80 md:w-120 lg:w-130 2xl:w-150 object-cover object-center z-0" src="../../../assets/images/home-page/blogs/blogs-banner-nobg.png" alt="Background Image">
        <div class="relative flex flex-col items-center text-center ml-4 md:ml-12 lg:ml-20 2xl:ml-32 py-2 mobileL:py-8 z-10">
            <h2 class="font-tahu text-white-1 text-2xl md:text-4xl lg:text-7xl 2xl:text-9xl">Our Signature Wax</h2>
            <h4 class="text-white-1 text-xs md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl mt-1 mobileL:mt-4">
                Know what is our secret!<br />
                #LayBarePh #Waxing #Philippines #LayBare #Manila
            </h4>
            <button class="rounded-full bg-brown-3 text-white-1 mt-2 mobileL:mt-4 py-2 px-4" @click="$router.push('/blog/our-signature-wax')">
                Read More
            </button>
        </div>
    </div>
    <div class="w-full absolute block z-20">
        <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
  components: { Header,  Footer },

  mounted() {
    this.$gtag.pageview(this.$route);
  },
}
</script>

<style>
.blogs-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .blogs-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .blogs-banner-container {
      height: 110vh;
    }
    .space-top {
    margin-top: 128px;
    }
}

@media (max-width:480px) {
    .blogs-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.blogs-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

.coming-soon-text {
    color: #FFF;
    text-shadow: 0px 4px 0px #AF7462;
    font-family: Mermaid;
}

@media only screen and (min-width: 768px) {
    .blogs-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
}

@media only screen and (min-width: 992px) {
    .blogs-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
}

@media only screen and (min-width: 1200px) {
    .blogs-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
}

</style>